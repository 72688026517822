import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { CmdModal, CmdModalFooterButton } from '@commander-services/gui-components';
import { useLocation } from 'wouter';
import { useQueryClient } from '@tanstack/react-query';
import { ICustomer } from '../../../interfaces/customer';
import LoaderService from '../../../Services/LoaderService';
import VehicleService from '../../../Services/VehicleService';
import UserService from '../../../Services/UserService';
import showMessage from '../../Toastr/ToastService';
import * as VehiclesState from '../../../store/recoil/vehicles';
import * as CustomersState from '../../../store/recoil/customers';
import MainMenuService from '../MainMenuService';
import * as MainMenuState from '../MainMenuState';
import { URL_MAPS } from '../../../router/constants';
import './customersStyles.scss';
import List from './List';
import CustomerService, { saveSelectedCustomers } from '../../Customers/CustomerService';
import { IMainMenuItem } from '../interfaces';
import { IVehicles } from '../../Vehicles/interfaces';
import * as BluecoinState from '../../OnlineMapNew/Bluecoins/BluecoinState';
import * as WaypointState from '../../OnlineMapNew/Waypoints/WaypointState';

const SELECTED_CUSTOMERS_LIMIT = 50;

export default function SelectCustomers(): JSX.Element {
  const queryClient = useQueryClient();
  const { formatMessage: f } = useIntl();
  const [pathname, navigate] = useLocation();

  const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);

  const customers = useRecoilValue<ICustomer[]>(CustomersState.customers);
  const [selectedCustomers, setSelectedCustomers] = useRecoilState<number[]>(
    CustomersState.selectedCustomers
  );
  const setSelectedTempCustomer = useSetRecoilState<number[]>(CustomersState.selectedTempCustomers);
  const selectedTempCustomers = useRecoilValue<number[]>(CustomersState.selectedTempCustomers);
  const selectedVehicles = useRecoilValue<number[]>(VehiclesState.selectedVehicles);
  const setSelectedVehicles = useSetRecoilState<number[]>(VehiclesState.selectedVehicles);
  const vehicles = useRecoilValue<IVehicles>(VehiclesState.vehicles);
  const setMenuItems = useSetRecoilState<IMainMenuItem[]>(MainMenuState.menuItems);
  const setCarRental = useSetRecoilState<string>(MainMenuState.carRental);
  const setActiveMenuParentId = useSetRecoilState<number>(MainMenuState.activeMenuParentId);
  const setMenuId = useSetRecoilState<number>(MainMenuState.activeMenuId);
  const setCustomers = useSetRecoilState<ICustomer[]>(CustomersState.customers);
  const setIsBluecoinOpen = useSetRecoilState<boolean>(BluecoinState.isBluecoinsOpen);
  const setIsWayponitsOpen = useSetRecoilState<boolean>(WaypointState.isWaypointsOpen);
  const setSelectedBluecoins = useSetRecoilState(BluecoinState.selectedBluecoins);

  const handleOpenModal = async () => {
    LoaderService.showLoader();
    const customersList = await CustomerService.getCustomers();
    if (customersList) {
      setCustomers(customersList);
    }
    LoaderService.showLoader(false);
    setSelectedTempCustomer(selectedCustomers);
    setIsModalOpened(true);
  };

  const handleCloseModal = () => {
    setIsModalOpened(false);
  };

  const handleCloseWpAndBle = () => {
    setIsBluecoinOpen(false);
    setIsWayponitsOpen(false);
    setSelectedBluecoins([]);
  };

  const handleSelectCustomers = async () => {
    handleCloseWpAndBle();

    if (
      selectedTempCustomers.length === 0 ||
      customers.filter((i) => selectedTempCustomers.includes(i.id)).length === 0
    ) {
      showMessage('', 'selectCustomers.error', 'warning');
      return;
    }
    if (selectedTempCustomers.length > SELECTED_CUSTOMERS_LIMIT) {
      showMessage(
        '',
        f({ id: 'selectCustomers.maximumSelectedCustomers' }, { _count: SELECTED_CUSTOMERS_LIMIT }),
        'warning'
      );
      return;
    }

    const unselectedCustomers = selectedCustomers.filter(
      (item) => !selectedTempCustomers.includes(item)
    );

    if (unselectedCustomers.length > 0) {
      setSelectedVehicles((state: number[]) =>
        VehicleService.unselectAllVehiclesByCustomerId(state, vehicles, unselectedCustomers)
      );
    }

    if (selectedTempCustomers) {
      LoaderService.showLoader();
      const response = await saveSelectedCustomers(selectedTempCustomers);
      if (response) {
        const mainMenu = await MainMenuService.get();
        if (mainMenu && mainMenu.panel) {
          setMenuItems(mainMenu.panel.items);
          if (mainMenu.carRental && mainMenu.carRental.enabled) {
            setCarRental(mainMenu.carRental.url);
          }
        }

        setSelectedCustomers(selectedTempCustomers);

        // Invalidate user data query to refresh user data
        // (because it runs useEffect in UserData/index.tsx and sets selectedCustomers)
        queryClient.invalidateQueries({ queryKey: ['userData'] });
        queryClient.invalidateQueries({ queryKey: ['customers'] });
        queryClient.invalidateQueries({ queryKey: ['vehicles'] });
        queryClient.invalidateQueries({ queryKey: ['vehicleGroups'] });

        UserService.setDefaultCustomer(selectedTempCustomers);

        if (selectedTempCustomers.length > 0) {
          const newSelectedVehicles: number[] = [];
          selectedTempCustomers.forEach((customerId: number) => {
            const vehiclesData = VehicleService.getSelectedVehiclesByCustomerId(
              vehicles,
              selectedVehicles,
              customerId
            );
            Object.keys(vehiclesData).forEach((vehicleId: string) => {
              const vehicle = vehiclesData[Number(vehicleId)];
              if (vehicle) {
                newSelectedVehicles.push(vehicle.id);
              }
            });
          });

          if (selectedTempCustomers.length === 1) {
            const vehiclesByCustomer = VehicleService.getVehiclesCustomerId(
              vehicles,
              [],
              selectedTempCustomers[0]
            );
            if (vehiclesByCustomer.length === 1) {
              if (vehiclesByCustomer[0]) {
                setSelectedVehicles([vehiclesByCustomer[0].id]);
              }
            }
          }
          setSelectedVehicles(newSelectedVehicles);
        }

        LoaderService.showLoader(false);

        setIsModalOpened(false);

        // If we are on maps page, redirect to fist available item in menu
        if (pathname === URL_MAPS && mainMenu) {
          if (!mainMenu.panel.items.find((item: IMainMenuItem) => item.link === URL_MAPS)) {
            if (mainMenu.panel.items[0].link) {
              setActiveMenuParentId(mainMenu.panel.items[0].id);
              navigate(mainMenu.panel.items[0].link);
            } else {
              setActiveMenuParentId(mainMenu.panel.items[0].id);
              setMenuId(mainMenu.panel.items[0].items[0].id);
              navigate(mainMenu.panel.items[0].items[0].link);
            }
          }
        }
      }
    }
  };

  const modalFooterButtons: CmdModalFooterButton[] = [
    {
      id: 'closeSelectCustomerModal',
      type: 'button',
      title: f({ id: 'form.close' }),
      className: 'e-button',
      closeCallback: handleCloseModal,
    },
    {
      id: 'submitSelectCustomerModal',
      type: 'submit',
      title: f({ id: 'navigator.selectCustomers.modal.submit' }),
      className: 'e-button e-button--gray',
      submitCallback: handleSelectCustomers,
    },
  ];

  return (
    <>
      <div className="sb-nav-sub-menu-wrapper">
        <div className="sb-nav-sub-menu-item">
          <a
            href="#"
            className="subMenu menuLink"
            onClick={handleOpenModal}
            data-cy="button-select-customers"
          >
            {' '}
            {f({ id: 'navigator.selectCustomers.button' })}{' '}
          </a>
        </div>
      </div>
      {isModalOpened && (
        <div style={{ textIndent: 0 }}>
          <CmdModal
            id="selectedCustomers"
            title={f({ id: 'navigator.selectCustomers.modal.title' })}
            footerButtons={modalFooterButtons}
            insideScroll={true}
          >
            <h5 className="fs-5 font-weight-normal mb-1 mt-0" style={{ lineHeight: 'normal' }}>
              <FormattedMessage id="navigator.selectCustomers.modal.content" />
            </h5>
            {selectedTempCustomers.length > SELECTED_CUSTOMERS_LIMIT && (
              <h5 className="fs-5 font-weight-normal mb-1 mt-0" style={{ lineHeight: 'normal' }}>
                {f(
                  { id: 'selectCustomers.maximumSelectedCustomers' },
                  { _count: SELECTED_CUSTOMERS_LIMIT }
                )}
              </h5>
            )}
            <div style={{ minHeight: '210px' }}>
              <List />
            </div>
          </CmdModal>
        </div>
      )}
    </>
  );
}
