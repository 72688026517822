import * as React from 'react';
import { CmdLoader } from '@commander-services/gui-components';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { DefaultParams, useLocation } from 'wouter';
import * as AppState from '../store/recoil/AppState';
import MainMenu from './MainMenu';
import NavigatorContainer from './NavigatorContainer';
import PageContent from './PageContent';
import { LanguagesContext, LanguageContextProps } from './LanguagesContext';
import Toastr from './Toastr';
// import JWTService from '../Services/JWTService';
import UserService from '../Services/UserService';
// import TokenChecker from './TokenChecker';
// import MainMenuService from './MainMenu/MainMenuService';
// import LoaderService from '../Services/LoaderService';
import * as MainMenuState from './MainMenu/MainMenuState';
// import configJs from '../../config';
import Broadcast from './Broadcast';
import MaintenanceToast from './Maintenance/Toast';
import * as NavigatorState from './Navigator/NavigatorState';
// import Error404 from './Error404';
import * as CustomersState from '../store/recoil/customers';

const Login = React.lazy(() => import('./Login'));

interface ILayoutProps {
  access?: boolean;
  isNavigatorDisabled?: boolean;
  component?: React.ComponentType<any>;
  newStyle?: boolean;
  isRideBook?: boolean;
  children?: React.ReactNode;
  routerParams?: DefaultParams;
}

export default function Layout(props: ILayoutProps) {
  // const [, setNavigate] = useLocation();
  const showLoader = useRecoilValue<boolean>(AppState.setLoader);
  // const setCarRental = useSetRecoilState(MainMenuState.carRental);
  // const [menuItems, setMenuItems] = useRecoilState(MainMenuState.menuItems);
  // const [activeMenuParentId, setActiveMenuParentId] = useRecoilState<number>(
  //   MainMenuState.activeMenuParentId
  // );
  // const selectedCustomers = useRecoilValue<number[]>(CustomersState.selectedCustomers);
  const [isNavigatorDisabled, setIsNavigatorDisabled] = useRecoilState<boolean>(
    NavigatorState.isDisabled
  );
  // const [url, setUrl] = useRecoilState<string>(MainMenuState.url);
  // const [activeMenuId, setMenuId] = useRecoilState<number>(MainMenuState.activeMenuId);

  // const loadMenu = React.useCallback(async () => {
  //   LoaderService.showLoader(true);
  //   const allItems = await MainMenuService.get();
  //   if (!allItems || allItems?.panel?.items?.length === 0) {
  //     UserService.logOut();
  //     console.warn('Empty menu items. Logging out. ');
  //     LoaderService.showLoader(false);
  //   }
  //
  //   const menu = allItems.panel || [];
  //   if (allItems.carRental && allItems.carRental.enabled) {
  //     setCarRental(allItems.carRental.url);
  //     LoaderService.showLoader(false);
  //   }
  //   if (menu && menu.items && menu.items.length > 0) {
  //     setMenuItems(menu.items);
  //     LoaderService.showLoader(false);
  //
  //     if (
  //       activeMenuId === 1 &&
  //       // menu.items.find((item) => item.id === activeMenuId) &&
  //       activeMenuParentId === 0 &&
  //       (menu.items[0].items || menu.items[0].link !== configJs.Login.homeUrl)
  //     ) {
  //       if (!menu.items[0].items) {
  //         setMenuId(menu.items[0].id);
  //         setActiveMenuParentId(0);
  //         setUrl(menu.items[0].link);
  //         setNavigate(menu.items[0].link);
  //       } else {
  //         setMenuId(menu.items[0].items[Object.keys(menu.items[0].items)[0]].id);
  //         setActiveMenuParentId(menu.items[0].id);
  //         setUrl(menu.items[0].items[Object.keys(menu.items[0].items)[0]].link);
  //         setNavigate(menu.items[0].items[Object.keys(menu.items[0].items)[0]].link);
  //       }
  //     }
  //     // if (!menu.items[0].items) {
  //     //   setMenuId(menu.items[0].id);
  //     //   setActiveMenuParentId(0);
  //     //   setUrl(menu.items[0].link);
  //     //   setNavigate(menu.items[0].link);
  //     // } else {
  //     //   setMenuId(menu.items[0].items[Object.keys(menu.items[0].items)[0]].id);
  //     //   setActiveMenuParentId(menu.items[0].id);
  //     //   setUrl(menu.items[0].items[Object.keys(menu.items[0].items)[0]].link);
  //     // }
  //   }
  // }, [
  //   activeMenuId,
  //   activeMenuParentId,
  //   setActiveMenuParentId,
  //   setCarRental,
  //   setMenuId,
  //   setMenuItems,
  //   setNavigate,
  //   setUrl,
  // ]);

  // React.useEffect(() => {
  //   if (UserService.isLogged()) {
  //     if (window.location.pathname === '/') {
  //       LoaderService.showLoader(true);
  //     }
  //     // if (Object.keys(menuItems).length === 0) {
  //     //   // loadMenu();
  //     //   if (window.location.pathname === '/') {
  //     //     LoaderService.showLoader(false);
  //     //   }
  //     // }
  //   }
  // }, [menuItems, selectedCustomers]);

  React.useEffect(() => {
    if (props.isNavigatorDisabled && !isNavigatorDisabled) {
      setIsNavigatorDisabled(true);
    } else if (!props.isNavigatorDisabled && isNavigatorDisabled) {
      setIsNavigatorDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const routeProps = { ...props };
  delete routeProps.component;

  let accessEnabled = true;
  if (props.access !== undefined) {
    accessEnabled = props.access;
  }

  if (!accessEnabled) {
    // navigate('/not-found'); // ked odoberiem pravo na zakaznika toto sposobuje bad state error
    // const homeUrl = config.Login.homeUrl;
    // return homeUrl ? (
    //   // <Redirect to={homeUrl} />
    //   <Redirect to="/" />
    // ) : (
    //   <LanguagesContext.Consumer>
    //     {(context: LanguageContextProps) => <Login {...matchProps} context={context} />}
    //   </LanguagesContext.Consumer>
    // );
  }

  // if (UserService.isLogged() && !JWTService.isTokenExpired()) {
  if (UserService.isLogged()) {
    const oldStyleContent = (
      <div>
        <div id="application">
          <Broadcast />
          <div
            id="wrapper"
            className="navigator-collapse"
            style={{
              paddingLeft:
                window.location.pathname.search('ride-map') !== -1 ||
                window.location.pathname.search('overview_tours/detail') !== -1
                  ? 0
                  : 216,
            }}
          >
            <MainMenu />
            {/* <TokenChecker /> */}
            <PageContent
              isRideBook={props.isRideBook || false}
              isNavigatorDisabled={isNavigatorDisabled || false}
            >
              <Toastr />
              <MaintenanceToast />
              {accessEnabled && props.component && <props.component router={props.routerParams} />}
              {/* {!accessEnabled && !showLoader && <Error404 />} */}
            </PageContent>
            {!isNavigatorDisabled && <NavigatorContainer />}
          </div>
        </div>
        {showLoader && <CmdLoader inContent={true} />}
      </div>
    );

    const newStyleContent = (
      <div className="w-page">
        <Broadcast />
        <aside className="w-navbar">
          <MainMenu />
        </aside>
        <aside className="w-navbar-navigator">
          {!isNavigatorDisabled && <NavigatorContainer />}
        </aside>
        <div className="w-page-content">
          <Toastr />
          <MaintenanceToast />
          {props.component && <props.component router={props.routerParams} />}
          {props.children}
        </div>
        {showLoader && <CmdLoader />}
      </div>
    );

    return props.newStyle ? newStyleContent : oldStyleContent;
  }

  return (
    <LanguagesContext.Consumer>
      {(context: LanguageContextProps) => <Login context={context} />}
    </LanguagesContext.Consumer>
  );
}
