import {
  IEventDataChangePayload,
  IEventVehiclePositionPayload,
  EventBluecoinPositionPayload,
  IGdprMapSettings,
  IMessageExportInProgress,
  IMessageLogout,
  IMessageReportCreated,
  ISocketServerBulkMessage,
  IUnitDataProcessorDefaultTemplateChange,
  IUnitDataProcessorNavigatorData,
  IUnitDataProcessorUserTemplateChange,
} from './interfaces';
import {
  EVENT_CONNECTION_CLOSE,
  EVENT_CONNECTION_ERROR,
  EVENT_CONNECTION_OPEN,
  EVENT_DATA,
  EVENT_DATA_BULK,
  EVENT_DATA_CHANGE_BULK,
  EVENT_DEFAULT_TEMPLATE_CHANGE,
  EVENT_DEFAULT_TEMPLATE_CHANGE_BULK,
  EVENT_ECO_DRIVE_VIOLATION,
  EVENT_ECO_DRIVE_VIOLATION_BULK,
  EVENT_EMPTY_USER_DATA,
  EVENT_EMPTY_VEHICLES,
  EVENT_EXPORT_IN_PROGRESS,
  EVENT_GDPR_SETTINGS,
  EVENT_LOGOUT,
  EVENT_REPORT_CREATED,
  EVENT_RIDE_CHANGED,
  EVENT_USER_TEMPLATE_CHANGE,
  EVENT_VEHICLE_POSITION,
  EVENT_VEHICLE_POSITION_BULK,
  EVENT_BLUECOIN_POSITION_BULK,
  EVENT_PERMISSIONS_CHANGED,
} from './constants';

export const handleLogoutMessageReceived = (message: IMessageLogout): void => {
  const data = {
    event: EVENT_LOGOUT,
    payload: message,
  };
  postMessage(data);
};

export const handleReportCreatedMessageReceived = (message: IMessageReportCreated): void => {
  const data = {
    event: EVENT_REPORT_CREATED,
    payload: message,
  };
  postMessage(data);
};

export const handleUserTemplateChange = (message: IUnitDataProcessorUserTemplateChange): void => {
  const data = {
    event: EVENT_USER_TEMPLATE_CHANGE,
    payload: message,
  };
  postMessage(data);
};

export const handleVehiclePositionMessageReceived = (
  message: IEventVehiclePositionPayload
): void => {
  const data = {
    event: EVENT_VEHICLE_POSITION,
    payload: message,
  };
  postMessage(data);
};

export const handleRideChanged = (vehicleId: number): void => {
  const data = {
    event: EVENT_RIDE_CHANGED,
    payload: vehicleId,
  };
  postMessage(data);
};

export const handleEcoDriveViolationMessageRecieve = (message: Record<string, any>): void => {
  const data = {
    event: EVENT_ECO_DRIVE_VIOLATION,
    payload: message,
  };
  postMessage(data);
};

export const handleEcoDriveViolationBulkMessageRecieve = (message: Record<string, any>): void => {
  const data = {
    event: EVENT_ECO_DRIVE_VIOLATION_BULK,
    payload: message,
  };
  postMessage(data);
};

export const handleDefaultTemplateChange = (
  message: IUnitDataProcessorDefaultTemplateChange
): void => {
  const data = {
    event: EVENT_DEFAULT_TEMPLATE_CHANGE,
    payload: message,
  };
  postMessage(data);
};

export const handleDefaultTemplateChangeBulk = (
  message: ISocketServerBulkMessage<IUnitDataProcessorDefaultTemplateChange>
): void => {
  const data = {
    event: EVENT_DEFAULT_TEMPLATE_CHANGE_BULK,
    payload: message,
  };
  postMessage(data);
};

export const handleEmptyVehicles = (): void => {
  const data = {
    event: EVENT_EMPTY_VEHICLES,
  };
  postMessage(data);
};

export const handlePermissionsChanged = (): void => {
  const data = {
    event: EVENT_PERMISSIONS_CHANGED,
  };
  postMessage(data);
};

export const handleEmptyUserData = (): void => {
  const data = {
    event: EVENT_EMPTY_USER_DATA,
  };
  postMessage(data);
};

export const handleOnNavigatorDataMessageRecieved = (
  message: IUnitDataProcessorNavigatorData
): void => {
  const data = {
    event: EVENT_DATA,
    payload: message,
  };
  postMessage(data);
};

export const handleDataBulkMessageRecieved = (
  message: ISocketServerBulkMessage<IUnitDataProcessorNavigatorData>
): void => {
  const data = {
    event: EVENT_DATA_BULK,
    payload: message,
  };
  postMessage(data);
};

export const handleVehiclePositionBulkMessageReceived = (
  message: ISocketServerBulkMessage<IEventVehiclePositionPayload>
): void => {
  const data = {
    event: EVENT_VEHICLE_POSITION_BULK,
    payload: message,
  };
  postMessage(data);
};

export const handleBluecoinPositionMessageReceived = (
  message: EventBluecoinPositionPayload
): void => {
  const data = {
    event: EVENT_BLUECOIN_POSITION_BULK,
    payload: message,
  };
  postMessage(data);
};

export const handleDataChangeBulkMessageRecieved = (
  message: ISocketServerBulkMessage<IEventDataChangePayload>
): void => {
  const data = {
    event: EVENT_DATA_CHANGE_BULK,
    payload: message,
  };
  postMessage(data);
};

export const handleExportInProgress = (message: IMessageExportInProgress): void => {
  const data = {
    event: EVENT_EXPORT_IN_PROGRESS,
    payload: message,
  };
  postMessage(data);
};

export const handleConnectionOpen = (): void => {
  const data = {
    event: EVENT_CONNECTION_OPEN,
    payload: null,
  };
  postMessage(data);
};

export const handleConnectionClose = (): void => {
  const data = {
    event: EVENT_CONNECTION_CLOSE,
    payload: null,
  };
  postMessage(data);
};

export const handleConnectionError = (error: string | false): void => {
  const data = {
    event: EVENT_CONNECTION_ERROR,
    payload: error,
  };
  postMessage(data);
};

export const getGdprMapSettings = (
  gdprProfiles: any,
  userId: number | null,
  driverId: number | null
) => {
  const value: IGdprMapSettings[] = [];
  gdprProfiles.forEach((profile: IGdprMapSettings) => {
    if (profile.authMap && profile.authMap[0]) {
      value.push({ customerId: profile.customerId, authMap: profile.authMap[0].enabled });
    }
  });

  return { userId, driverId, value };
};

export const handleGdprMessage = (
  gdprProfiles: any,
  userId: number | null,
  driverId: number | null
): void => {
  const data = {
    event: EVENT_GDPR_SETTINGS,
    payload: getGdprMapSettings(gdprProfiles, userId, driverId),
  };
  postMessage(data);
};
